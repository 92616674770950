import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { Link } from 'gatsby-plugin-modal-routing-3'
import Card from './card'

const Sectiontitle = styled.h2`
font-size: 1.0625rem !important;
`

const Grid = styled.section`
display: grid;
grid-template-columns: repeat(4, 18.5vw);
grid-gap: 6vw;
grid-row-gap: 2.5vw;
@media (max-width: 599px) {
  grid-template-columns: repeat(2, 43vw);
  grid-gap: 4vw;
}
&:last-of-type {
  padding-bottom: 7.5vw;
}
h3 {
  padding-bottom: 0 !important;
  @media (max-width: 599px) {
    font-size: .9125rem;
  }
}
h6 {
  letter-spacing: .5px;
  font-size: .75rem;
  color: rgba(0,0,0,0.5);
}
dl {
  padding-top: 1vh;
  display: grid;
  grid-template-columns: 0 5fr;
  grid-auto-rows: min-content;
  align-items: baseline;
}
dt, dd { 
  line-height: 1.8em;
  font-size: .875rem;
  @media (max-width: 599px) {
    font-size: .75rem;
    line-height: 1.75em;
  }
}
dt { 
  font-size: 0;
  visibility: none;
}
`

const Team  = () => {
    const data = useStaticQuery(graphql`
    query {
      contentfulPage(title: {eq: "About"}) {
        referencePages {
          ... on ContentfulPerson {
            id
            name
            slug
            position
            email
            phone
            photo {
              gatsbyImageData
            }
          }
        }
      }
    }
    `)

    return (
        <>
            <Sectiontitle>Team</Sectiontitle>
            <Grid>
                {data.contentfulPage.referencePages.map(person => (
                <Card key={person.id}>
                <Link to={`/about/${person.slug}`} asModal><div><GatsbyImage image={person.photo.gatsbyImageData} alt="image" objectFit="cover" /><h3>{person.name}</h3>{person.position && <h6>{person.position}</h6>}</div></Link>
                </Card>
                ))}
            </Grid>
        </>
    )
}

export default Team